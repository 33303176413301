import React from 'react';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import classNames from 'classnames';
import { groupsConfig } from '../translation.config';
import { CommentVariants } from '../constants/groupForumsConstants';
import CommentSkeleton from '../components/skeletons/CommentSkeleton';
import Comment from '../components/Comment';
import { usePost } from '../contexts/PostContext';
import { ForumComment } from '../types';
import usePostContext from '../hooks/usePostContext';

export type CommentRepliesProps = {
  replies: ForumComment[];
  onShowReplies: () => void;
  onLoadMore: () => void;
  isOpen: boolean;
  isLoading: boolean;
  isFetchingMore: boolean;
  hasMore: boolean;
  loadingError: boolean;
  parentId: string;
} & WithTranslationsProps;

const CommentReplies = ({
  replies,
  onShowReplies,
  onLoadMore,
  isOpen,
  isLoading,
  isFetchingMore,
  parentId,
  hasMore,
  loadingError,
  translate
}: CommentRepliesProps): JSX.Element => {
  const { replyCommentHighlight } = usePost();
  const { state } = usePostContext();

  const handleShowRepliesKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      onShowReplies();
    }
  };

  const handleLoadMoreKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      onLoadMore();
    }
  };

  if (!isOpen || (loadingError && (!replies || replies.length === 0))) {
    return (
      <div className='groups-forums-comment-replies-show-more'>
        <div
          role='button'
          tabIndex={0}
          onClick={onShowReplies}
          onKeyDown={handleShowRepliesKeyDown}
          className='groups-forums-comment-replies-show-more-btn'>
          <div className='groups-forums-comment-replies-show-more-dash' />
          {translate('Action.ShowReplies')}
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className='group-forums-comment-replies-loading'>
        <CommentSkeleton />
        <CommentSkeleton />
        <CommentSkeleton />
      </div>
    );
  }

  return (
    <div className='groups-forums-comment-replies'>
      {replies &&
        replies.map(reply => {
          const isActive = replyCommentHighlight === reply.id;

          if (state.blockedUserList.length > 0 && state.blockedUserList.includes(reply.createdBy)) {
            return null;
          }

          return (
            <div
              key={reply.id}
              className={classNames('groups-forums-comment-reply', {
                'groups-forums-comment-reply-active': isActive
              })}>
              <Comment
                key={reply.id}
                variant={CommentVariants.Reply}
                isActive={isActive}
                id={reply.id}
                createdBy={reply.createdBy}
                creatorInfo={reply.creatorInfo}
                createdAt={reply.createdAt}
                content={reply.content.plainText}
                threadId={null}
                channelId={reply.parentId}
                parentCommentId={parentId}
                reactions={reply.reactions}
                replies={[]}
              />
            </div>
          );
        })}
      {hasMore && !isFetchingMore && (
        <div className='groups-forums-comment-replies-show-more'>
          <div
            role='button'
            tabIndex={0}
            onClick={onLoadMore}
            onKeyDown={handleLoadMoreKeyDown}
            className='groups-forums-comment-replies-show-more-btn'>
            <div className='groups-forums-comment-replies-show-more-dash' />
            {translate('Action.ShowMoreReplies')}
          </div>
        </div>
      )}
      {isFetchingMore && <div className='spinner spinner-default spinner-infinite-scroll' />}
    </div>
  );
};
export default withTranslations(CommentReplies, groupsConfig);
