import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSystemFeedback } from 'react-style-guide';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { groupsConfig } from '../translation.config';
import TextContentEditor from '../components/TextContentEditor';
import CategoriesList from './CategoriesList';
import groupForumsConstants from '../constants/groupForumsConstants';
import useForumCategories from '../hooks/useForumCategories';
import forumsService from '../services/forumsService';
import useForumPost from '../hooks/useForumPost';
import { useForumPermissions } from '../contexts/ForumPermissionsContext';
import { logGroupForumsClickEvent } from '../utils/logging';

export type PostComposerProps = {
  groupId: number;
  defaultCategoryId: string;
  editingPostId?: string;
} & WithTranslationsProps;

const PostComposer = ({
  groupId,
  defaultCategoryId,
  editingPostId,
  translate
}: PostComposerProps): JSX.Element => {
  const history = useHistory();
  const [activeCategoryId, setActiveCategoryId] = useState(defaultCategoryId);
  const [submitErrorKey, setSubmitErrorKey] = useState<string | null>(null);
  const { canCreatePost, canEditPost } = useForumPermissions();

  const {
    isLoading: isLoadingCategories,
    errorKey: categoriesErrorKey,
    forumCategories
  } = useForumCategories(groupId);

  const { isLoading: isLoadingPost, errorKey: postErrorKey, forumPost } = useForumPost(
    groupId,
    defaultCategoryId,
    editingPostId
  );
  const { systemFeedbackService } = useSystemFeedback();

  const onSubmit = useCallback(
    async ({ title, content }) => {
      try {
        if (editingPostId) {
          if (!forumPost) return;
          await forumsService.updateGroupForumComment(
            groupId,
            activeCategoryId,
            editingPostId,
            forumPost.firstComment.id,
            content
          );
          history.push(groupForumsConstants.router.getPostRoute(activeCategoryId, editingPostId));
        } else {
          const {
            categoryId: postCategoryId,
            id: postId
          } = await forumsService.createGroupForumPost(groupId, activeCategoryId, title, content);
          history.push(groupForumsConstants.router.getPostRoute(postCategoryId, postId));
        }

        const logEventData = editingPostId
          ? { clickTargetType: 'editPost', clickTargetId: editingPostId }
          : { clickTargetType: 'createPost', clickTargetId: activeCategoryId };

        logGroupForumsClickEvent({
          groupId,
          ...logEventData
        });
      } catch (error) {
        const typedError = error as { status: number };
        if (typedError.status === 400) {
          setSubmitErrorKey('Error.ForumPostModerated');
        } else {
          setSubmitErrorKey('NetworkError');
        }
      }
    },
    [editingPostId, activeCategoryId, groupId, forumPost, history]
  );

  const onBack = useCallback(() => {
    if (editingPostId) {
      history.push(groupForumsConstants.router.getPostRoute(defaultCategoryId, editingPostId));
    } else {
      history.push(groupForumsConstants.router.getCategoryRoute(defaultCategoryId));
    }
  }, [history, editingPostId, defaultCategoryId]);

  const onChange = useCallback(() => {
    if (!submitErrorKey) return;
    setSubmitErrorKey(null); // clear error key when user changes content
  }, [submitErrorKey]);

  useEffect(() => {
    if (categoriesErrorKey) {
      systemFeedbackService.warning(translate(categoriesErrorKey));
    }
  }, [categoriesErrorKey, systemFeedbackService, translate]);

  useEffect(() => {
    if (postErrorKey) {
      systemFeedbackService.warning(translate(postErrorKey));
    }
  }, [postErrorKey, systemFeedbackService, translate]);

  useEffect(() => {
    if (!canCreatePost || (editingPostId && forumPost && !canEditPost(forumPost.createdBy))) {
      onBack();
    }
  }, [canCreatePost, canEditPost, editingPostId, forumPost, onBack]);

  const defaultTitle = forumPost?.name;
  const defaultContent = forumPost?.firstComment?.content.plainText;

  return (
    <div className='post-composer'>
      <TextContentEditor
        hasTitle
        headerText={translate(editingPostId ? 'Action.EditPost' : 'Action.CreatePost')}
        contentPlaceholder={translate('Label.WriteSomething')}
        submitText={translate('Action.Post')}
        customControls={
          <div className='post-composer-categories-control'>
            <h5 className='post-composer-categories-label'>{translate('Heading.Categories')}</h5>
            <div className='post-composer-categories-list'>
              <CategoriesList
                categories={forumCategories}
                activeCategoryId={activeCategoryId}
                onSetActiveCategory={setActiveCategoryId}
                isLoading={isLoadingCategories}
                locked={!!editingPostId}
              />
            </div>
          </div>
        }
        titleMaxLength={groupForumsConstants.limits.postTitleMaxLength}
        contentMaxLength={groupForumsConstants.limits.postContentMaxLength}
        defaultTitle={defaultTitle}
        defaultContent={defaultContent}
        titleLocked={!!editingPostId}
        errorKey={submitErrorKey}
        onSubmit={onSubmit}
        onBack={onBack}
        onChange={onChange}
        isLoading={isLoadingPost}
      />
    </div>
  );
};

PostComposer.displayName = 'PostComposer';

export default withTranslations(PostComposer, groupsConfig);
