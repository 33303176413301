import React, { useCallback, useMemo } from 'react';
import { Popover, useSystemFeedback } from 'react-style-guide';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { groupsConfig } from '../translation.config';
import groupForumsConstants from '../constants/groupForumsConstants';
import { useForumPermissions } from '../contexts/ForumPermissionsContext';
import DropdownMenuItem from './DropdownMenuItem';
import { usePost } from '../contexts/PostContext';
import { UIActionType } from '../store/forumsUIAction';
import useForumsUIContext from '../hooks/useForumsUIContext';
import { NotificationPreferenceType } from '../types';

export type CommentMenuProps = {
  createdBy: number;
  commentId: string;
  button: JSX.Element;
  isReply: boolean;
  parentCommentId?: string;
  threadId: string | null;
  channelId: string;
  onSubscribe: () => void;
} & WithTranslationsProps;

const CommentMenu = ({
  createdBy,
  commentId,
  button,
  isReply,
  parentCommentId,
  channelId,
  onSubscribe,
  translate
}: CommentMenuProps): JSX.Element => {
  const { canEditComment, canDeleteComment, canBlockUser, canSubscribe } = useForumPermissions();
  const { categoryId, groupId, postId, comments, handleDeleteComment, setEditComment } = usePost();
  const { systemFeedbackService } = useSystemFeedback();
  const { dispatch } = useForumsUIContext();

  const showEditComment = useMemo(() => canEditComment(createdBy), [canEditComment, createdBy]);
  const showBlockUser = useMemo(() => canBlockUser(createdBy), [canBlockUser, createdBy]);
  const showDeleteComment = useMemo(() => canDeleteComment(createdBy), [
    canDeleteComment,
    createdBy
  ]);
  const showSubscribe = useMemo(() => canSubscribe(createdBy), [canSubscribe, createdBy]);

  const handleEditComment = useCallback(() => {
    setEditComment(commentId, parentCommentId);
  }, [commentId, parentCommentId, setEditComment]);

  const onDeleteComment = useCallback(async () => {
    const success = await handleDeleteComment(commentId, parentCommentId);
    if (!success) {
      systemFeedbackService.warning(translate('NetworkError'));
    }
  }, [handleDeleteComment, commentId, parentCommentId, systemFeedbackService, translate]);

  const handleCopyLink = useCallback(async () => {
    if (navigator.clipboard) {
      try {
        const commentUrl = groupForumsConstants.deepLinks.groupForumCommentUrl(
          groupId,
          categoryId,
          postId,
          commentId
        );
        await navigator.clipboard.writeText(commentUrl);
        systemFeedbackService.success(translate('Label.LinkCopied'));
      } catch {
        systemFeedbackService.warning(translate('Error.CopyLink'));
      }
    }
  }, [categoryId, commentId, groupId, postId, systemFeedbackService, translate]);

  const handleReport = useCallback(() => {
    const reportUrl = groupForumsConstants.urls.reportAbuse(
      groupId,
      postId,
      channelId,
      commentId,
      true
    );
    window.location.href = reportUrl;
  }, [commentId, groupId, postId, channelId]);

  const [isSubscribed, isSubscriptionLoaded] = useMemo(() => {
    const comment = comments.find(c => c.id === commentId);
    return [
      comment?.notificationPreference === NotificationPreferenceType.All,
      comment?.notificationPreference !== undefined
    ];
  }, [comments, commentId]);

  const onBlockUser = () => {
    dispatch({
      type: UIActionType.OPEN_BLOCK_DIALOG,
      payload: {
        userId: createdBy
      }
    });
  };

  return (
    <Popover
      id='group-forums-comment-dropdown-menu'
      button={button}
      trigger='click'
      placement='bottom'>
      <ul className='dropdown-menu' role='menu'>
        {showEditComment && (
          <DropdownMenuItem
            translateKey={isReply ? 'Label.EditReply' : 'Label.EditComment'}
            action={handleEditComment}
          />
        )}
        {showDeleteComment && (
          <DropdownMenuItem
            translateKey={isReply ? 'Label.DeleteReply' : 'Label.DeleteComment'}
            action={onDeleteComment}
          />
        )}
        {navigator.clipboard && (
          <DropdownMenuItem translateKey='Label.CopyLink' action={handleCopyLink} />
        )}
        {showBlockUser && <DropdownMenuItem translateKey='Label.BlockUser' action={onBlockUser} />}
        {showSubscribe && (
          <DropdownMenuItem
            translateKey={
              isSubscribed ? 'Label.UnsubscribeFromComment' : 'Label.SubscribeToComment'
            }
            action={onSubscribe}
            disabled={!isSubscriptionLoaded}
          />
        )}
        <DropdownMenuItem translateKey='Label.ReportAbuse' action={handleReport} />
      </ul>
    </Popover>
  );
};

export default withTranslations(CommentMenu, groupsConfig);
